<template>
  <div style="margin: 0 24px">
    <comp-part title="患者管理"></comp-part>
    <div class="search">
      <template v-if="queryOpenHandle">
        <span class="el-span">病历号：</span>
        <el-input size="small" class="el-input" v-model="recordId" placeholder="请输入病历号" oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
        <span class="el-span">患者姓名：</span>
        <el-input size="small" class="el-input" v-model="patientName" placeholder="请输入患者姓名" ></el-input>
        <el-button
          size="small"
          @click="onSearch"
          class="search-btn">
          查询
        </el-button>
      </template>
      <el-button
      v-if="addOpenHandle"
        type="primary"
        size="small"
        class="search-btn"
        @click="onAddPatient">
        添加患者
      </el-button>
    </div>
    <table-comp
      :table-opts="tableOpts"
      :data="tableData"
      :page="currentPage"
      :total="totalPage"
      :page-size="pageSize"
      :show-number="true"
      :open-handle="true"
      :handle-opts="handleOpts"
      :handle-width="220"
      @handleSizeChange="sizeChange"
      @handleCurrentChange="pageChange"
    >
      <template #alertMsg="{ msg }">
        <el-popover
          placement="bottom-start"
          title=""
          width="220"
          trigger="hover"
          data-html="true"
        >
          <div>
            <div class="alert-date alert-font">报警事件{{msg.obsTime ? '（' + moment.unix(msg.obsTime / 1000).format('Y-MM-DD') + '）': ''}}</div>
            <div class="alert-content">
              {{msg.observations}}
            </div>
          </div>
          <div slot="reference">
            <div v-if="msg.observations" class="show-visit-records">
              <span style="display: flex; align-items: center"><i style="display: inline-block;width: 4px; height: 4px; border-radius: 50%; background-color: #F56C6C; margin-right: 6px"></i>{{msg.name || '-'}}<br></span>
            </div>
          </div>
        </el-popover>
        <div v-if="!msg.observations"><i style="display: inline-block;width: 4px; height: 4px; border-radius: 50%; background-color: #67c23a; margin-right: 6px"></i>{{msg.name || '-'}}</div>
      </template>
      <template v-if="patientArchiveHandle" #btn_1="{ msg }">
        <el-button style="font-size: 14px;" size="mini" type="text" @click="other_1(msg)">患者档案</el-button>
      </template>
      <template v-if="uploadReportHandle" #btn_2="{ msg }">
        <el-button style="font-size: 14px;" size="mini" type="text" @click="other_2(msg)">上传程控随访PDF</el-button>
      </template>
    </table-comp>
  </div>
</template>

<script>
import TableComp from '@/components/Table/Table'
import CompPart from '@/components/Part'
import { getPatientName } from '@/service/module/datareport'
import { getPatientList } from '@/service/module/enterpatient'
import { hasFollowUp, invalidFollowUpRecord } from '@/service/module/followup'
const moment = require('moment')
export default {
  components: {
    TableComp,
    CompPart
  },
  data () {
    return {
      moment,
      patientIdList: [], // 搜索-病理号列表
      patientNameList: [], // 搜索-姓名列表
      recordId: '',
      patientName: '',
      searchData: {
        input: '',
        seqNo: '',
        periodStatus: ''
      },
      userInfo: null,
      tableOpts: [ // 列表表头设置
        {
          label: '患者姓名',
          prop: 'name',
          slot: 'alertMsg'
        },
        {
          label: '性别',
          prop: 'gender',
          width: '60px',
          render: (h, params) => {
            const gender = params.row.gender
            let genderText = ''
            switch (gender) {
              case '2' : genderText = '女'
                break
              case '1' : genderText = '男'
                break
            }
            return h('span', {}, genderText)
          }
        },
        {
          label: '年龄',
          prop: 'age',
          width: '60px',
          render: (h, params) => {
            return h('span', {}, params.row?.age || '-')
          }
        },
        {
          label: '病历号',
          prop: 'medicalRecordNumber',
          width: '80px',
          render: (h, params) => {
            return h('span', {}, params.row?.medicalRecordNumber || '-')
          }
        },
        {
          label: 'CIED品牌型号',
          prop: 'mode',
          render: (h, params) => {
            let brandModel
            if (!params.row?.brandName && !params.row.modelName) {
              brandModel = '-'
            } else {
              brandModel = params.row.brandName + params.row.modelName
            }

            return h('span', {}, brandModel)
          }
        },
        {
          label: '电池剩余寿命',
          prop: 'battery',
          render: (h, params) => {
            return h('span', {}, params.row?.batteryLongevity || '-')
          }
        },
        {
          label: '植入日期',
          prop: 'implantDate',
          render: (h, params) => {
            const implantDate = params.row?.implantDate ? moment.unix(params.row.implantDate / 1000).format('Y-MM-DD') : '-'
            return h('span', {}, implantDate)
          }
        },
        {
          label: '注册时间',
          prop: 'createAt',
          render: (h, params) => {
            const createDate = moment.unix(params.row.createAt / 1000).format('Y-MM-DD HH:mm:ss')
            return h('span', {}, createDate)
          }
        }
      ],
      handleOpts: {
      },
      value: null,

      tableData: [],
      totalPage: 0, // 总条数
      currentPage: 1, // 当前条数
      pageSize: 20, // 每页条数

      options: [], // 患者列表

      followUpDetails: {},

      queryOpenHandle: false,
      addOpenHandle: false,
      uploadReportHandle: false,
      patientArchiveHandle: false

    }
  },
  mounted () {
    this.getPatientList(1, this.pageSize)
    const authList = JSON.parse(sessionStorage.getItem('auth')).perms
    authList.find(i => {
      if (i === 'patient-mgr:query') {
        this.queryOpenHandle = true
      }
      if (i === 'patient-mgr:add-patient') {
        this.addOpenHandle = true
      }
      if (i === 'patient-mgr:upload-report') {
        this.uploadReportHandle = true
      }
      if (i === 'patient-mgr:patient-archive') {
        this.patientArchiveHandle = true
      }
    })
  },
  methods: {
    splicingString (list) {
      let str = ''
      list.forEach(i => {
        str += i.text + '<br/>'
      })
      return str
    },
    async getFollowUpList (pageNum, pageSize) {
      try {
        this.searchData.pageNum = pageNum
        this.searchData.pageSize = pageSize
        // const res = await getFollowUpList(
        //   this.searchData)
        // if (res) {
        //   this.tableData = res.data.results
        //   this.totalPage = res.data.total
        //   this.currentPage = res.data.currentPage
        //   this.pageSize = res.data.pageSize
        // }
      } catch (e) {
        console.log('awaitList-err:', e)
      }
    },
    async getPatientList (pageNum, pageSize) {
      getPatientList({
        pageNum: pageNum,
        pageSize: pageSize,
        recordNo: this.recordId,
        patientName: this.patientName
      }).then(res => {
        if (res.code === 200) {
          this.tableData = res.data.results
          this.totalPage = res.data.total
        } else {
          this.$message.error(res.message)
        }
      })
    },
    async other_2 (data) {
      this._send('$click', '患者列表上传程控随访PDF点击', '患者列表上传程控随访PDF按钮 发生点击行为触发', {
        accountName: JSON.parse(sessionStorage.getItem('user')).accountName,
        doctorName: JSON.parse(sessionStorage.getItem('user')).doctorName,
        hospitalName: JSON.parse(sessionStorage.getItem('user')).hospitalName
      })
      const [err, res] = await this.$to(hasFollowUp({
        patientId: data.id
      }))
      if (res && res.code === 200) {
        this.userInfo = data
        if (!res.data.exist) {
          this.createNew(data)
        } else {
          this.$confirm('当前还有未完成的上传任务，新建上传将导致数据丢失', '提示', {
            confirmButtonText: '继续上传',
            cancelButtonText: '新建上传',
            type: 'warning',
            distinguishCancelAndClose: true
          }).then(() => {
            this._send('$click', '患者列表继续上传点击', '患者列表继续上传按钮 发生点击行为触发', {
              accountName: JSON.parse(sessionStorage.getItem('user')).accountName,
              doctorName: JSON.parse(sessionStorage.getItem('user')).doctorName,
              hospitalName: JSON.parse(sessionStorage.getItem('user')).hospitalName
            })
            this.$router.push({
              name: 'createFollowup',
              query: {
                recordId: res.data.recordId,
                patientId: data.id,
                name: data.name,
                medicalRecordNumber: data.medicalRecordNumber,
                isNewFollowUp: false,
                fromPage: 'enterPatient'
              }
            })
          }).catch(action => {
            if (action === 'close') {
              return
            }
            this.invalidFollowUpRecord(res.data.recordId)
            this._send('$click', '患者列表新建上传点击', '患者列表新建上传按钮 发生点击行为触发', {
              accountName: JSON.parse(sessionStorage.getItem('user')).accountName,
              doctorName: JSON.parse(sessionStorage.getItem('user')).doctorName,
              hospitalName: JSON.parse(sessionStorage.getItem('user')).hospitalName
            })
          })
        }
      } else {
        this.$message.error('获取随访状态失败')
      }
      if (err) {
        this.$message.error('网络错误')
        throw new Error(err)
      }
    },
    other_1 (data) {
      console.log(data)
      this.$router.push({
        name: 'patientFiles',
        query: {
          patientId: data.id
        }
      })
    },
    createNew (data) {
      this.$router.push({
        name: 'createFollowup',
        query: {
          recordId: this.recordId,
          patientId: data.id,
          name: data.name,
          medicalRecordNumber: data.medicalRecordNumber,
          isNewFollowUp: true,
          fromPage: 'enterPatient'

        }
      })
    },
    sizeChange (val) {
      this.pageSize = val
      this.getPatientList(this.currentPage, val)
    },
    pageChange (val) {
      this.currentPage = val
      this.getPatientList(val, this.pageSize)
    },
    onSearch () {
      this.getPatientList(1, this.pageSize, this.recordId)
    },
    onAddPatient () {
      this._send('$click', '添加患者点击', '添加患者按钮 发生点击行为触发', {
        accountName: JSON.parse(sessionStorage.getItem('user')).accountName,
        doctorName: JSON.parse(sessionStorage.getItem('user')).doctorName,
        hospitalName: JSON.parse(sessionStorage.getItem('user')).hospitalName
      })
      this.$router.push({
        name: 'AddPatient',
        query: {
          // id: data.id
        }
      })
    },
    dataFilter (val) {
      if (val.length > 4) {
        getPatientName(val).then(res => {
          if (res.data) {
            this.options = res.data
          }
        })
      }
    },
    async invalidFollowUpRecord (recordId) {
      invalidFollowUpRecord({
        recordId: recordId
      }).then(res => {
        if (res.code === 200 && this.userInfo) {
          this.createNew(this.userInfo)
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .search {
    float: right;
    margin-bottom: 12px;
    height: 32px;
    line-height: 32px;
    .el-input {
      width: 280px;
      margin-right: 24px;
    }
    .el-span {
      font-weight: 400;
      font-size: 14px;
    }
    .search-btn {
      font-size: 14px;;
    }
  }
  .alert-font {
    font-size: 12px;
    line-height: 20px;
  }
  .alert-date {
    color: #F56C6C;
  }
</style>
